<script>
  import { link, location } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import FaArrowLeft from 'svelte-icons/fa/FaArrowLeft.svelte';
  import FaArrowRight from 'svelte-icons/fa/FaArrowRight.svelte';
  import FaBars from 'svelte-icons/fa/FaBars.svelte';
  import FaRegFilePdf from 'svelte-icons/fa/FaRegFilePdf.svelte';
  import Container from './layout/container.svelte';

  import Sidebar from './sidebar.svelte';
  import navegacaoStore from '../store';

  let dados;

  navegacaoStore.subscribe((dadosStore) => {
    dados = dadosStore;
  });

  const updatePaginaAtual = () => {
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, paginaAtual: Number($location.split('/')[1]) };
    });
  };
</script>

<div class="contato">
  <a href="#/contato"><img id="boneco" src="assets/Contato/cliente.png" alt="" /></a>
  <a href="#/contato" id="balao">
    <img id="" src="assets/Contato/balao-de-fala (1).png" alt="" />
    <h6>Dúvidas?</h6>
  </a>
</div>

<style>
  .contato {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 300px;
    display: inline-flex;
    vertical-align: middle;
    z-index: 1000;
  }
  #boneco {
    margin-left: 5px;
    width: 100px;
    z-index: 1001;
  }
  #balao {
  }

  #balao img {
    width: 80px;
    height: 50px;
    position: fixed;
    left: 100px;
    bottom: 50px;
    z-index: 500;
  }
  h6 {
    position: fixed;
    color: aqua;
    z-index: 600;
    left: 110px;
    bottom: 60px;
  }
</style>
