<script>
  import { link, location } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import IoIosClose from 'svelte-icons/io/IoIosClose.svelte';

  import navegacaoStore from '../store';

  let dados;
  let width;

  navegacaoStore.subscribe((dadosStore) => {
    dados = dadosStore;
  });

  const toggleSidebar = (mob = false) => {
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, sidebarAberta: !dados.sidebarAberta };
    });
  };
  const updatePaginaAtual = () => {
    width < 767 && toggleSidebar();
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, paginaAtual: Number($location.split('/')[1]) };
    });
  };
</script>

<svelte:window bind:innerWidth={width} />
<div
  class={dados.sidebarAberta ? 'fundo-mobile active' : 'fundo-mobile'}
  on:click={toggleSidebar}
/>
<div class="hamburger" on:click={() => toggleSidebar(true)}>
  <svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="5" rx="2.5" fill="white" />
    <rect y="10" width="32" height="5" rx="2.5" fill="white" />
    <rect y="20" width="32" height="5" rx="2.5" fill="white" />
  </svg>
</div>
<div class={dados.sidebarAberta ? 'sidebar open' : 'sidebar'}>
  <div class="toggle-menu" on:click={toggleSidebar}>
    <span class="icone">
      <svg
        width="10"
        height="14"
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.18645 7.82199C0.612441 7.42431 0.612442 6.57569 1.18645 6.17801L9.68051
          0.293151C10.3437 -0.166329 11.25 0.308327 11.25 1.11515L11.25 12.8849C11.25 13.6917
          10.3437 14.1663 9.6805 13.7069L1.18645 7.82199Z"
          fill="#C5C5C5"
        />
      </svg>
      <svg
        width="10"
        height="14"
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.18645 7.82199C0.612441 7.42431 0.612442 6.57569 1.18645 6.17801L9.68051
          0.293151C10.3437 -0.166329 11.25 0.308327 11.25 1.11515L11.25 12.8849C11.25 13.6917
          10.3437 14.1663 9.6805 13.7069L1.18645 7.82199Z"
          fill="#C5C5C5"
        />
      </svg>
    </span>
    <span class="titulo">
      <span class="abrir">abrir</span>
      <span class="fechar">fechar</span>
      <br />
      menu
    </span>
  </div>
  <div class="fechar-mobile" on:click={toggleSidebar}>
    <span class="titulo">FECHAR</span>
    <span class="icone">
      <IoIosClose />
    </span>
  </div>

  <div class="sidebar-content">
    <a
      on:click={updatePaginaAtual}
      href="/1"
      use:link
      use:active={{ className: 'active' }}
      class="unidade"
    >
      <span class="icone">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45.055"
          height="54.372"
          viewBox="0 0 45.055 54.372"
        >
          <g id="Camada_2" data-name="Camada 2" transform="translate(0.07 -0.096)">
            <g id="Layer_1" data-name="Layer 1">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M44.51,29.11c-.58-2-1.47-3.94-2.2-5.91a40,40,0,0,1-2-5.7c-.74-3.73.77-6.7,3.67-9l.39-.34a.22.22,0,0,0,0-.32C42.94,6,41.53,4.27,40,2.5c-.4-.46-.77-.95-1.15-1.42S38.26.67,37.8,1a11.71,11.71,0,0,1-4.62,1.72,13.4,13.4,0,0,1-7.21-1A28.079,28.079,0,0,1,22.8.16a.48.48,0,0,0-.48,0C21.4.59,20,1.25,19.09,1.63a13.44,13.44,0,0,1-7.22,1A11.76,11.76,0,0,1,7.25,1C6.8.72,6.54.7,6.19,1.13S5.44,2,5,2.5C3.55,4.25,2.15,6,.66,7.77a.31.31,0,0,0,0,.42L1,8.48c2.89,2.32,4.41,5.29,3.67,9a40,40,0,0,1-2,5.7c-.73,2-1.62,3.89-2.2,5.91a15.92,15.92,0,0,0,.23,9,13.87,13.87,0,0,0,5.05,6.74,39.7,39.7,0,0,0,12,6.27,7.12,7.12,0,0,1,3.59,2,13.4,13.4,0,0,1,.84,1.24.29.29,0,0,0,.48,0,13.4,13.4,0,0,1,.84-1.24,7.12,7.12,0,0,1,3.59-2,39.7,39.7,0,0,0,12-6.27,13.87,13.87,0,0,0,5.05-6.74,15.92,15.92,0,0,0,.37-8.98Z"
                fill="#fff"
                class="path-brasao"
              />
            </g>
          </g>
        </svg>
      </span>
      <span class="numero-icone">A</span>
      <span class="titulo">Apresentação</span>
    </a>
    {#each dados.paginas as pagina, i}
      {#if i > 0 && i < dados.paginas.length - 1}
        <a
          on:click={updatePaginaAtual}
          href="/{i + 1}"
          use:link
          use:active={{ className: 'active' }}
          class="unidade"
        >
          <span class="icone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45.055"
              height="54.372"
              viewBox="0 0 45.055 54.372"
            >
              <g id="Camada_2" data-name="Camada 2" transform="translate(0.07 -0.096)">
                <g id="Layer_1" data-name="Layer 1">
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M44.51,29.11c-.58-2-1.47-3.94-2.2-5.91a40,40,0,0,1-2-5.7c-.74-3.73.77-6.7,3.67-9l.39-.34a.22.22,0,0,0,0-.32C42.94,6,41.53,4.27,40,2.5c-.4-.46-.77-.95-1.15-1.42S38.26.67,37.8,1a11.71,11.71,0,0,1-4.62,1.72,13.4,13.4,0,0,1-7.21-1A28.079,28.079,0,0,1,22.8.16a.48.48,0,0,0-.48,0C21.4.59,20,1.25,19.09,1.63a13.44,13.44,0,0,1-7.22,1A11.76,11.76,0,0,1,7.25,1C6.8.72,6.54.7,6.19,1.13S5.44,2,5,2.5C3.55,4.25,2.15,6,.66,7.77a.31.31,0,0,0,0,.42L1,8.48c2.89,2.32,4.41,5.29,3.67,9a40,40,0,0,1-2,5.7c-.73,2-1.62,3.89-2.2,5.91a15.92,15.92,0,0,0,.23,9,13.87,13.87,0,0,0,5.05,6.74,39.7,39.7,0,0,0,12,6.27,7.12,7.12,0,0,1,3.59,2,13.4,13.4,0,0,1,.84,1.24.29.29,0,0,0,.48,0,13.4,13.4,0,0,1,.84-1.24,7.12,7.12,0,0,1,3.59-2,39.7,39.7,0,0,0,12-6.27,13.87,13.87,0,0,0,5.05-6.74,15.92,15.92,0,0,0,.37-8.98Z"
                    fill="#fff"
                    class="path-brasao"
                  />
                </g>
              </g>
            </svg>
          </span>
          <span class="numero-icone">{i}</span>
          <span class="titulo">{pagina}</span>
        </a>
      {/if}
    {/each}
    <!-- <div class="spacing" /> -->
    <a
      href="/{dados.paginas.length}"
      use:link
      class="referencias"
      on:click={updatePaginaAtual}
      use:active={{ className: 'active' }}
    >
      <span class="icone">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.055 54.372">
          <g id="referencia" transform="translate(-609 -470)">
            <g id="Camada_2" data-name="Camada 2" transform="translate(609.07 469.904)">
              <g id="Layer_1" data-name="Layer 1">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M44.51,29.11c-.58-2-1.47-3.94-2.2-5.91a40,40,0,0,1-2-5.7c-.74-3.73.77-6.7,3.67-9l.39-.34a.22.22,0,0,0,0-.32C42.94,6,41.53,4.27,40,2.5c-.4-.46-.77-.95-1.15-1.42S38.26.67,37.8,1a11.71,11.71,0,0,1-4.62,1.72,13.4,13.4,0,0,1-7.21-1A28.079,28.079,0,0,1,22.8.16a.48.48,0,0,0-.48,0C21.4.59,20,1.25,19.09,1.63a13.44,13.44,0,0,1-7.22,1A11.76,11.76,0,0,1,7.25,1C6.8.72,6.54.7,6.19,1.13S5.44,2,5,2.5C3.55,4.25,2.15,6,.66,7.77a.31.31,0,0,0,0,.42L1,8.48c2.89,2.32,4.41,5.29,3.67,9a40,40,0,0,1-2,5.7c-.73,2-1.62,3.89-2.2,5.91a15.92,15.92,0,0,0,.23,9,13.87,13.87,0,0,0,5.05,6.74,39.7,39.7,0,0,0,12,6.27,7.12,7.12,0,0,1,3.59,2,13.4,13.4,0,0,1,.84,1.24.29.29,0,0,0,.48,0,13.4,13.4,0,0,1,.84-1.24,7.12,7.12,0,0,1,3.59-2,39.7,39.7,0,0,0,12-6.27,13.87,13.87,0,0,0,5.05-6.74,15.92,15.92,0,0,0,.37-8.98Z"
                  fill="#fff"
                  class="path-brasao"
                />
              </g>
            </g>
            <g id="Camada_2-2" data-name="Camada 2" transform="translate(621 479)">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M20.64,4.75a1,1,0,0,0-.91-.15L15.9,5.81V2.55a1,1,0,0,0-1.54-.85L9.17,5.07v-4A1,1,0,0,0,7.38.39L.22,9.46a.61.61,0,0,0-.13.22h0A1.41,1.41,0,0,0,0,10H0V29.27a1,1,0,0,0,1,1H5.71A.87.87,0,0,0,6,30.24H6l14.33-4.52a1,1,0,0,0,.71-1V5.57a1,1,0,0,0-.4-.82ZM13.86,6.46,7.91,8.33l5.95-3.9ZM2,11.11H4.69V24.34H2Zm5.09-4.7L4.34,8.24,3.18,9l4-5ZM2,26.37H4.69v1.88H2ZM19,24,6.73,27.88v-17l8.46-2.67h0L19,7Z"
                  fill="var(--COR_MODULO)"
                />
              </g>
            </g>
          </g>
        </svg>
      </span>
      <span class="titulo">Referências</span>
    </a>
    <!-- <div class="spacing" /> -->
    <a
      class="modulo-pdf"
      href="assets/pdf/FUNDO ROTATIVO_DEPEN_Modulo_1.pdf"
      download="FUNDO ROTATIVO_DEPEN_Modulo_1.pdf"
      target="_blank"
    >
      <span class="icone">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.055 54.372">
          <g id="pdf" transform="translate(-609 -470)">
            <g id="Camada_2" data-name="Camada 2" transform="translate(609.07 469.904)">
              <g id="Layer_1" data-name="Layer 1">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M44.51,29.11c-.58-2-1.47-3.94-2.2-5.91a40,40,0,0,1-2-5.7c-.74-3.73.77-6.7,3.67-9l.39-.34a.22.22,0,0,0,0-.32C42.94,6,41.53,4.27,40,2.5c-.4-.46-.77-.95-1.15-1.42S38.26.67,37.8,1a11.71,11.71,0,0,1-4.62,1.72,13.4,13.4,0,0,1-7.21-1A28.079,28.079,0,0,1,22.8.16a.48.48,0,0,0-.48,0C21.4.59,20,1.25,19.09,1.63a13.44,13.44,0,0,1-7.22,1A11.76,11.76,0,0,1,7.25,1C6.8.72,6.54.7,6.19,1.13S5.44,2,5,2.5C3.55,4.25,2.15,6,.66,7.77a.31.31,0,0,0,0,.42L1,8.48c2.89,2.32,4.41,5.29,3.67,9a40,40,0,0,1-2,5.7c-.73,2-1.62,3.89-2.2,5.91a15.92,15.92,0,0,0,.23,9,13.87,13.87,0,0,0,5.05,6.74,39.7,39.7,0,0,0,12,6.27,7.12,7.12,0,0,1,3.59,2,13.4,13.4,0,0,1,.84,1.24.29.29,0,0,0,.48,0,13.4,13.4,0,0,1,.84-1.24,7.12,7.12,0,0,1,3.59-2,39.7,39.7,0,0,0,12-6.27,13.87,13.87,0,0,0,5.05-6.74,15.92,15.92,0,0,0,.37-8.98Z"
                  fill="#fff"
                  class="path-brasao"
                />
              </g>
            </g>
            <g id="Camada_2-2" data-name="Camada 2" transform="translate(623.002 485)">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M12.53,0c.12.11.25.21.36.33l4.41,5a.84.84,0,0,1,.21.57V23a1,1,0,0,1-1.06,1H1.07A1,1,0,0,1,0,23V1.07A1.05,1.05,0,0,1,.75,0Zm4,16V6.25H12.59c-.54,0-.68-.14-.68-.69V1H1V16Zm-10,1.25v5.37c0,.13,0,.26.17.26H8.89a2.85,2.85,0,0,0,1.83-.75,3,3,0,0,0,.87-2.41,2.47,2.47,0,0,0-2.06-2.46,8.64,8.64,0,0,0-2.38-.08ZM2.85,20.92c.51-.07,1-.09,1.44-.19a1.76,1.76,0,0,0,1.39-1.84,1.65,1.65,0,0,0-1.53-1.67,18.45,18.45,0,0,0-2,0,3.81,3.81,0,0,0-.59.08v5.62H2.85Zm13.08-3.74H12.45v5.7h1.3v-2.3h2v-1h-2V18.23h2.17Z"
                  fill="var(--COR_MODULO)"
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M6.43,5.58c-.18.46.07.81.47,1.14C8.21,7.8,9.51,8.91,10.81,10a.6.6,0,0,0,.33.09c.84,0,1.69,0,2.54.07a2.48,2.48,0,0,1,1,.28,1.17,1.17,0,0,1,.58,1.5,1.19,1.19,0,0,1-1.43.78,2.31,2.31,0,0,1-.71-.29c-.74-.48-1.46-1-2.2-1.49a.76.76,0,0,0-.39-.1,16,16,0,0,0-3.23.45c-.73.2-1.44.47-2.16.73a.39.39,0,0,0-.18.26A4.91,4.91,0,0,1,4.17,14a1.64,1.64,0,0,1-1,.66.75.75,0,0,1-1-.77,1.44,1.44,0,0,1,.43-1.05,7.63,7.63,0,0,1,1.51-1.15.57.57,0,0,0,.23-.3c.39-1.57.77-3.14,1.16-4.71a.33.33,0,0,0-.09-.35,11.8,11.8,0,0,1-1-1.15,1.81,1.81,0,0,1-.27-.71,1,1,0,0,1,.42-1,1,1,0,0,1,1.11-.15,1.07,1.07,0,0,1,.6.68,3.85,3.85,0,0,1,.16,1.58ZM6.2,7.09l-1,4.09a17.09,17.09,0,0,1,4.6-1ZM12,10.8v.07c.52.34,1,.69,1.57,1a1.3,1.3,0,0,0,.52.14.42.42,0,0,0,.44-.28.51.51,0,0,0-.32-.74A7,7,0,0,0,12,10.8ZM5.72,5.57a3.31,3.31,0,0,0-.15-1.42A.26.26,0,0,0,5.15,4,.38.38,0,0,0,5,4.53a2.87,2.87,0,0,0,.72,1.04ZM2.93,14A1.56,1.56,0,0,0,4,12.58C3.6,13,3,13.22,2.93,14Z"
                  fill="var(--COR_MODULO)"
                />
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M7.81,21.93V18.34c0-.06.06-.18.11-.18a2.21,2.21,0,0,1,1.64.3,1.67,1.67,0,0,1,.64,1.45,1.91,1.91,0,0,1-.68,1.67,2.44,2.44,0,0,1-1.71.35Z"
                  fill="var(--COR_MODULO)"
                />
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M2.87,18.14c.53-.05,1-.14,1.41.36a.89.89,0,0,1,0,1,1.34,1.34,0,0,1-1.37.34Z"
                  fill="var(--COR_MODULO)"
                />
              </g>
            </g>
          </g>
        </svg>
      </span>
      <span class="titulo"> Módulo <br /> em PDF </span>
    </a>

  </div>
</div>

<style>
  /* Teste Vercel */
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(182, 182, 182, 0);
    border-radius: 10px;
    padding: 5px 0;
  }

  .sidebar {
    /* padding: 5px; */
    padding: 10px 2px;
    position: fixed;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--COR_MODULO);
    border-radius: 0 20px 20px 0;
    z-index: 100;
    box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 200ms ease;
    width: 90px;
    transform: translateY(-50%);
    top: 50%;
    overflow-x: hidden;
  }

  .sidebar:hover ::-webkit-scrollbar-thumb {
    background: rgba(182, 182, 182, 0.308);
  }

  a .titulo {
    position: absolute;
    width: 100px;
    margin-left: 18px;
    color: #000;
    left: -20px;
    transition: left 200ms ease, opacity 100ms ease;
    opacity: 0;
    font-size: 14px;
    z-index: 201;
  }

  .sidebar.open {
    width: 196px;
    left: 0;
  }

  .sidebar.open a .titulo {
    left: 50px;
    opacity: 1;
    transition: left 100ms ease, opacity 200ms ease;
    font-family: var(--FONT_CABIN);
    color: #fff;
    text-transform: uppercase;
  }

  a {
    padding: 6px 5px;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 9px;
    text-decoration: none;
  }
  a .icone {
    position: relative;
    z-index: 202;
  }
  a svg {
    /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2)); */
    width: 52px;
    height: 52px;
    transition: all 100ms ease;
    /* margin-left: 3px; */
  }
  
  .unidade svg {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
    width: 52px;
    height: 52px;
    transition: all 100ms ease;
    margin-left: 0px;
  }
  
  .numero-icone {
    position: absolute;
    left: 24px;
    color: var(--COR_MODULO);
    padding-top: 2px;
    font-size: 24px;
    font-family: var(--FONT_CABIN);
    font-weight: 700;
    z-index: 230;
  }
  .sidebar-content a:nth-child(3) .numero-icone {
    left: 26px;
  }
  .toggle-menu {
    display: flex;
    font-size: 13px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 7px 5px 5px 5px;
    cursor: pointer;
    transition: all 200ms ease;
    user-select: none;
  }
  .toggle-menu .fechar {
    display: none;
  }
  .sidebar.open .toggle-menu .fechar {
    display: inline-block;
  }
  .sidebar.open .toggle-menu .abrir {
    display: none;
  }
  .sidebar.open .toggle-menu {
    flex-direction: row;
  }
  .toggle-menu .icone {
    display: flex;
    transform: rotateY(180deg);
    transition: all 200ms ease;
  }
  .sidebar.open .toggle-menu .icone {
    transform: rotateY(0);
  }
  .toggle-menu .titulo {
    margin: 0 8px;
    color: #fff;
  }

  .path-brasao {
    fill: #fff;
  }

  .sidebar.open a:hover .titulo,
  .sidebar :global(a.active .titulo) {
    color: var(--COR_MODULO_FRACO);
    /* font-weight: 700; */
  }
  .sidebar :global(a.active .path-brasao),
  .sidebar :global(a:hover .path-brasao) {
    fill: var(--COR_MODULO_FRACO);
  }

  .sidebar :global(a:hover .path-brasao-deg) {
    fill: var(--COR_MODULO_FRACO);
  }

  .hamburger {
    display: none;
  }

  .fundo-mobile {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: rgba(97, 97, 97, 0.281);
    z-index: 100;
    display: none;
  }

  .fechar-mobile {
    display: none;
  }

  /* .gradiente {
    fill: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  } */

  @media (max-width: 800px) {
    .hamburger {
      position: fixed;
      top: 20px;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      z-index: 99;
      background: linear-gradient(to right, var(--COR_MODULO_FRACO), var(--COR_MODULO));
      border-radius: 10px;
      box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.9);
      cursor: pointer;
    }
    .hamburger svg {
      fill: #fff;
    }
    .sidebar {
      left: -150px;
    }
    .fundo-mobile.active {
      display: block;
    }
    .sidebar.open .fechar-mobile {
      left: 0;
    }
    .fechar-mobile .icone {
      width: 30px;
    }
  }

  .sidebar-content {
    width: 100%;
  }

  .sidebar-content {
    overflow-y: scroll;
    overflow-x: hidden;
  }

</style>
