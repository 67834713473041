<script>
  export let semlegenda = false;
</script>

<div class="container">
  <div class="col-md-10 row mx-auto justify-content-center">
    <div class="imagem">
      <slot />
    </div>
  </div>
  <br />
  <div class="col-md-10 row mx-auto justify-content-center">
    {#if !semlegenda}
      <div class="legenda">
        <slot name="legenda" />
      </div>
    {/if}
  </div>
</div>

<style>
  .imagem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
  }
  .imagem :global(img),
  .imagem :global(object) {
    width: 80%;
    border-radius: 30px;
  }

  .legenda {
    font-size: 0.85rem;
    font-family: var(--FONT_CABIN-CONDENSED);
    text-align: center;
    color: #504f51;
    font-weight: 400;
  }

  @media (max-width: 767px) {
    .imagem :global(img),
    .imagem :global(object) {
      width: 100% !important;
    }
  }
</style>
